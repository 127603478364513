import { schoolsControllerAddSchoolToUser } from './schoolsControllerAddSchoolToUser'
import { schoolsControllerChangeSchoolByAdmin } from './schoolsControllerChangeSchoolByAdmin'
import { schoolsControllerChangeSchoolInfo } from './schoolsControllerChangeSchoolInfo'
import { schoolsControllerCreateAuthKeys } from './schoolsControllerCreateAuthKeys'
import { schoolsControllerDecreaseRating } from './schoolsControllerDecreaseRating'
import { schoolsControllerGenerateTempUsers } from './schoolsControllerGenerateTempUsers'
import { schoolsControllerGetAdminSchools } from './schoolsControllerGetAdminSchools'
import { schoolsControllerGetSchoolAttributesCount } from './schoolsControllerGetSchoolAttributesCount'
import { schoolsControllerGetSchoolById } from './schoolsControllerGetSchoolById'
import { schoolsControllerGetSchoolFeature } from './schoolsControllerGetSchoolFeature'
import { schoolsControllerGetSchoolInfoByBin } from './schoolsControllerGetSchoolInfoByBin'
import { schoolsControllerGetSchoolsByUserId } from './schoolsControllerGetSchoolsByUserId'
import { schoolsControllerGetSchoolsWithFeatureToggles } from './schoolsControllerGetSchoolsWithFeatureToggles'
import { schoolsControllerGetStatistic } from './schoolsControllerGetStatistic'
import { schoolsControllerGetUserSchools } from './schoolsControllerGetUserSchools'
import { schoolsControllerIncreaseRating } from './schoolsControllerIncreaseRating'
import { schoolsControllerInsertEmailAsSchoolEmails } from './schoolsControllerInsertEmailAsSchoolEmails'
import { schoolsControllerSearchSchools } from './schoolsControllerSearchSchools'
import { schoolsControllerUpdateStatusCanBuyCertificate } from './schoolsControllerUpdateStatusCanBuyCertificate'

export const schoolsService = {
    schoolsControllerSearchSchools,
    schoolsControllerGetSchoolsWithFeatureToggles,
    schoolsControllerGetSchoolFeature,
    schoolsControllerGenerateTempUsers,
    schoolsControllerGetSchoolInfoByBin,
    schoolsControllerGetUserSchools,
    schoolsControllerGetAdminSchools,
    schoolsControllerCreateAuthKeys,
    schoolsControllerInsertEmailAsSchoolEmails,
    schoolsControllerGetSchoolsByUserId,
    schoolsControllerGetStatistic,
    schoolsControllerGetSchoolById,
    schoolsControllerGetSchoolAttributesCount,
    schoolsControllerAddSchoolToUser,
    schoolsControllerChangeSchoolByAdmin,
    schoolsControllerChangeSchoolInfo,
    schoolsControllerIncreaseRating,
    schoolsControllerDecreaseRating,
    schoolsControllerUpdateStatusCanBuyCertificate,
  }