import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerUpdateStatusCanBuyCertificateMutationRequest,
  SchoolsControllerUpdateStatusCanBuyCertificateMutationResponse,
  SchoolsControllerUpdateStatusCanBuyCertificatePathParams,
} from '../../ts/schoolsDTO/SchoolsControllerUpdateStatusCanBuyCertificate'

export function getSchoolsControllerUpdateStatusCanBuyCertificateUrl({
  schoolId,
}: {
  schoolId: SchoolsControllerUpdateStatusCanBuyCertificatePathParams['schoolId']
}) {
  return `/schools/${schoolId}/can_buy_certificate` as const
}

/**
 * {@link /schools/:schoolId/can_buy_certificate}
 */
export async function schoolsControllerUpdateStatusCanBuyCertificate(
  { schoolId }: { schoolId: SchoolsControllerUpdateStatusCanBuyCertificatePathParams['schoolId'] },
  data: SchoolsControllerUpdateStatusCanBuyCertificateMutationRequest,
  config: Partial<RequestConfig<SchoolsControllerUpdateStatusCanBuyCertificateMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    SchoolsControllerUpdateStatusCanBuyCertificateMutationResponse,
    ResponseErrorConfig<Error>,
    SchoolsControllerUpdateStatusCanBuyCertificateMutationRequest
  >({ method: 'PUT', url: getSchoolsControllerUpdateStatusCanBuyCertificateUrl({ schoolId }).toString(), data, ...requestConfig })
  return res
}